import { createResourceId } from 'src/utils/create-resource-id';
import { decode, JWT_EXPIRES_IN, JWT_SECRET, sign } from 'src/utils/jwt';
import { wait } from 'src/utils/wait';
import { users } from './data';
import myAxios from '../my-axios';
import { first } from 'lodash';


const ENDPOINT = '/users';
class AuthApi {
  async signIn(request) {
    const { username, password } = request;
    
    try {
      // Send a POST request to the server to authenticate the user
      const response = await myAxios.post('/login', {
        email:username,
        password,
      });
      const  userData  = response.data.data;
    
      return { userData};
    } catch (err) {
      console.error('[Auth Api]: ', err);
     
      throw new Error(err.response.data.message);
    }
  }

  async verify(request) {
    const { id, code } = request;
    
    try {
      // Send a POST request to the server to authenticate the user
       await myAxios.put(`${ENDPOINT}/user_validate`, {
        temp_id:id,
        code,
      });
      // response.data.data;
    } catch (err) {
      console.error('[Verify Api]: ', err);
     
      throw new Error(err.response.data.message);
    }
  }

  // async forgotPassword(request) {
  //   const { username } = request;
    
  //   try {
  //     // Send a POST request to the server to authenticate the user
  //     await myAxios.put('/users/admins/forgot-password', {
  //       email:username
  //     });
  //   } catch (err) {
  //     console.error('[Forget Password Api]: ', err);
     
  //     throw new Error(err.response.data.message);
  //   }
  // }

  // async resetPassword(request) {
  //   const { username,otp,new_password } = request;
    
  //   try {
  //     // Send a POST request to the server to authenticate the user
  //     await myAxios.put('/users/admins/reset-password', {
  //       email:username,
  //       otp,
  //       new_password
  //     });
  //   } catch (err) {
  //     console.error('[Reset Password Api]: ', err);
     
  //     throw new Error(err.response.data.message);
  //   }
  // }

  async signUp(request) {
    const { first_name,last_name,middle_name,email,sex,nationality,university,college, academicTitle } = request;

    try {
      // Send a POST request to the server to authenticate the user'
      
      const response = await myAxios.post(`${ENDPOINT}/create_temp`, {
        fname:first_name,
        lname:last_name,
        mname:middle_name,
        email:email,
        sex:sex,
        nationality_id:nationality.id,
        university_id:university.id,
        college_id:college.id,
        academic_title_id:academicTitle.id,
      });
      const  userData  = response.data.data;
    
      return {
        first_name,
        last_name,
        middle_name,
        email,
        sex,
        university,
        college,
        academicTitle,
        id:userData.user_id,
        name:userData.name
      };
    } catch (err) {
      console.error('[Sigup Api]: ', err);
     
      throw new Error(err.response.data.message);
    }
  }

  async completeSignUp(request) {
    const { id,password,phone,general_specialization,specific_specialization,nationality } = request;

    try {
      // Send a POST request to the server to authenticate the user'
      const body = {
        id,
        password,
        phone,
        general_specialization,
        specific_specialization,
        nationality_id:nationality.id
      };
      const response = await myAxios.post(`${ENDPOINT}/create`, body);
      const  {data}  = response.data.data;
    
      return body;
    } catch (err) {
      console.error('[Complete Sigup Api]: ', err);
     
      throw new Error(err.response.data.message);
    }
  }

  // me(request) {
  //   const { accessToken } = request;

  //   return new Promise((resolve, reject) => {
  //     try {
  //       // Decode access token
  //       const decodedToken = decode(accessToken);

  //       // Merge static users (data file) with persisted users (browser storage)
  //       const mergedUsers = [
  //         ...users,
  //         ...getPersistedUsers()
  //       ];

  //       // Find the user
  //       const { userId } = decodedToken;
  //       const user = mergedUsers.find((user) => user.id === userId);

  //       if (!user) {
  //         reject(new Error('Invalid authorization token'));
  //         return;
  //       }

  //       resolve({
  //         id: user.id,
  //         avatar: user.avatar,
  //         email: user.email,
  //         name: user.name,
  //         plan: user.plan
  //       });
  //     } catch (err) {
  //       console.error('[Auth Api]: ', err);
  //       reject(new Error('Internal server error'));
  //     }
  //   });
  // }
}

export const authApi = new AuthApi();
