import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { Layout as DashboardLayout } from 'src/layouts/dashboard';

// const IndexPage = lazy(() => import('src/pages/dashboard/index'));


// APP
// Posts
const PostCreatePage = lazy(() => import('src/pages/dashboard/posts/create'));
const PostEditPage = lazy(() => import('src/pages/dashboard/posts/edit'));
const PostDetailsPage = lazy(() => import('src/pages/dashboard/posts/detail'));
const MyPostsPage = lazy(() => import('src/pages/dashboard/posts/me'));

// Social
const SocialFeedPage = lazy(() => import('src/pages/dashboard/social/feed'));
const SocialProfilePage = lazy(() => import('src/pages/dashboard/social/profile'));
// Other
const AccountPage = lazy(() => import('src/pages/dashboard/account'));
const BlankPage = lazy(() => import('src/pages/dashboard/blank'));

export const dashboardRoutes = [
  {
    path: 'dashboard',
    element: (
      <DashboardLayout>
        <Suspense>
          <Outlet />
        </Suspense>
      </DashboardLayout>
    ),
    children: [
      {
        index: true,
        element: <SocialFeedPage />
      },
      {
        path: 'posts',
        children: [
         
          {
            index:true,
            path: 'create',
            element: <PostCreatePage />
          },
         
          {
            path: ':postId/edit',
            element: <PostEditPage />
          },
          {
            path: 'me',
            element: <MyPostsPage />
          },
          {
            path: ':postId',
            element: <PostDetailsPage />
          },
        ]
      },
      
     
     
      {
        path: 'social',
        children: [
          {
            path: 'feed',
            element: <SocialFeedPage />
          },
          {
            path: ':userId/profile',
            element: <SocialProfilePage />
          }
        ]
      },
      {
        path: 'account',
        element: <AccountPage />
      },
     
      {
        path: 'blank',
        element: <BlankPage />
      }
    ]
  }
];
