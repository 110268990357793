import { useDispatch as useReduxDispatch, useSelector as useReduxSelector } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import { rootReducer } from './root-reducer';

const preloadedState = loadFromLocalStorage(); // Load initial state from local storage

export const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.REACT_APP_ENABLE_REDUX_DEV_TOOLS === 'true',
  preloadedState: preloadedState, 

});


store.subscribe(() => {
  // Save data to local storage whenever the store changes
  saveToLocalStorage(store.getState());
});

export const useSelector = useReduxSelector;

export const useDispatch = () => useReduxDispatch();




function loadFromLocalStorage() {
  try {
    const serializedState = localStorage.getItem('resourcesList');
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    console.error('Error loading dl state from local storage:', err);
    return undefined;
  }
}

function saveToLocalStorage(state) {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('resourcesList', serializedState);
  } catch (err) {
    console.error('Error saving state to local storage:', err);
  }
}
