import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Popover,
  Typography,
  Stack
} from '@mui/material';
import { Presence } from 'src/components/presence';
import { customLocale } from 'src/utils/date-locale';
import { getFullURL } from 'src/utils/gen-image-url';
import { MyCircularProgress } from 'src/sections/components/circular-progress';
import { paths } from 'src/paths';

export const RequestsPopover = (props) => {
  const { anchorEl, requests = [],label = 'Requests', onClose, open = false,loading=true, ...other } = props;

  
  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: 'center',
        vertical: 'bottom'
      }}
      disableScrollLock
      onClose={onClose}
      open={open}
      PaperProps={{ sx: { width: 320 } }}
      {...other}>
      <Box sx={{ p: 2 }}>
        <Typography variant="h6">
          {label}
        </Typography>
      </Box>
      <Box sx={{ p: 2 }}>
        {
          loading?(
           <MyCircularProgress/>
          )
          :
          <List disablePadding>
          {requests.map((request,index) => {
            console.log(request)
            const user = request.user || request.post_user;;
            const showOnline = request.owner_status === 'approved';
            const status = request.owner_status;
            const last_update = request.last_update;
            const post_status = request.post_status;

            return (
              <ListItem
                component={Link}
                href={paths.dashboard.posts.details.replace(':postId', request.post_id)}
                disableGutters
                key={index}
              >
                <ListItemAvatar>
                  <Avatar
                    src={getFullURL(user.img_url)}
                    sx={{ cursor: 'pointer' }}
                  />
                </ListItemAvatar>
                <ListItemText
                  disableTypography
                  primary={(
                    <Stack
                    spacing={0}
                    direction='column'
                    >
                    <Link
                      color="text.primary"
                      wrap
                      sx={{ cursor: 'pointer' }}
                      underline="none"
                      variant="subtitle2"
                    >
                      {user.name}
                    </Link>
                    <Typography
                    color="text.secondary"
                    wrap
                    variant="subtitle2"
                  >
                    {request.post_title}
                  </Typography>
                    <Typography
                    color="text.secondary"
                    wrap
                    variant="caption"
                  >
                    {status}
                  </Typography>
                    </Stack>
                  )}
                />
                {/* {showOnline && (
                  <Presence
                    size="small"
                    status="online"
                  />
                )} */}
                {last_update && (
                  <Typography
                    color="text.secondary"
                    noWrap
                    variant="caption"
                  >
                    {last_update}
                  </Typography>
                )}
              </ListItem>
            );
          })}
        </List>
        }
        
      </Box>
    </Popover>
  );
};

RequestsPopover.propTypes = {
  anchorEl: PropTypes.any,
  contacts: PropTypes.array,
  onClose: PropTypes.func,
  open: PropTypes.bool
};
