import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { SvgIcon } from '@mui/material';
import { tokens } from 'src/locales/tokens';
import { paths } from 'src/paths';
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
export const useSections = () => {
  const { t } = useTranslation();

  return useMemo(() => {
    return [
      {
        items: [
          {
            title: t(tokens.nav.feed),
            path: paths.dashboard.social.feed,
            icon: (
              <SvgIcon fontSize="small">
                <DynamicFeedIcon />
              </SvgIcon>
            ),
           
          },
          {
            title: t(tokens.nav.account),
            path: paths.dashboard.account,
            icon: (
              <SvgIcon fontSize="small">
                <AccountBoxIcon />
              </SvgIcon>
            )
          }
        ]
      },
      
    ];
  }, [t]);
};
