
import { deepCopy } from 'src/utils/deep-copy';
// import { customer, customers, emails, invoices, logs } from './data';
import axios from 'src/api/my-axios'

const ENDPOINT = '/users';
class UsersApi {
  async getAll(request = {}) {
    // const { filters, page, rowsPerPage, sortBy, sortDir } = request;

    try {
      // Send a POST request to the server to authenticate the user
      const response = await axios.get(ENDPOINT);
  
      let users = response.data.data.list;
      let data = deepCopy(users);
      let count = response.data.data.total;
      
      return Promise.resolve({
        data,
        count
      });
    } catch (err) {
      console.error('[Get Users Api]: ', err);
      throw new Error(err.response.data.message);
    }

  }

  async search(request = {}) {
    // const { filters, page, rowsPerPage, sortBy, sortDir } = request;

    try {
      // Send a POST request to the server to authenticate the user
      const response = await axios.get(ENDPOINT);
  
      let users = response.data.data.list;
      let data = deepCopy(users);
      let count = response.data.data.total;
      
      return Promise.resolve({
        data,
        count
      });
    } catch (err) {
      console.error('[Search Users Api]: ', err);
      throw new Error(err.response.data.message);
    }

  }

  async getProfile()
  {
    try {
      // Send a POST request to the server to authenticate the user
      const response = await axios.get(`${ENDPOINT}/profile`);
  
      let data = response.data.data;
      
      return Promise.resolve({
        data
            });
    } catch (err) {
      console.error('[Get User Profile Api]: ', err);
      throw new Error(err.response.data.message);
    }
  }

  
  async getUserProfile(request)
  {
    const {id} = request;
    try {
      // Send a POST request to the server to authenticate the user
      const response = await axios.get(`${ENDPOINT}/profile/${id}`);
      let data = response.data.data;
      
      return Promise.resolve({
        data
            });
    } catch (err) {
      console.error('[Get User Profile Api]: ', err);
      throw new Error(err.response.data.message);
    }
  }
  
  async changePassword(request)
  {
    const {oldPassword, newPassword, id} = request;
    try {
      // Send a POST request to the server to authenticate the user
      const response = await axios.put(`${ENDPOINT}/change_password/${id}`,{
        old_password:oldPassword,
        new_password:newPassword
      });
      let data = response.data.data;
      
      return Promise.resolve({
        data
            });
    } catch (err) {
      console.error('[Change Password Api]: ', err);
      throw new Error(err.response.data.message);
    }

  }

  async update(request)
  {
    const {first_name, middle_name,last_name,sex,nationality_id,image=null,phone} = request;
    try {
      // Send a POST request to the server to authenticate the user
      let body = {
        fname:first_name,
        mname:middle_name,
        lname:last_name,
        sex:sex,
        nationality_id,
        phone:phone?phone:''
      };
      if(image)
      body = {
        ...body,
        image
      }

      const response = await axios.put(`${ENDPOINT}/update_personal`,body);

      let data = response.data.data;
      
      return Promise.resolve({
        data
            });
    } catch (err) {
      console.error('[Update Profile Api]: ', err);
      throw new Error(err.response.data.message);
    }

  }

  async updateScientific(request)
  {
    // academic_title: academic_title || null,
    // nationality:country || null,
    // college: college || null,
    // university: defaultUniversity || null,
    // general_specialization: general_specialization || "",
    // specific_specialization: specific_specialization || "",
    // certificates: certificates

    const {university, college,academic_title,general_specialization,specific_specialization, certificates = [], fields_of_interest=[]} = request;
    try {
      // Send a POST request to the server to authenticate the user
      let body = {
        university_id: university.id,
        college_id:college.id,
        academic_title_id:academic_title.id,
        general_specialization: general_specialization,
        specific_specialization: specific_specialization,
        certificates: certificates.map(c => {
          return {
            id:c.type.id,
            field_id:c.field.id,  
            thesis_title:c.title,
            year:c.year
          }
        }),
        fields_of_interest_ids:fields_of_interest.map(f => f.id),
      };
    

      const response = await axios.put(`${ENDPOINT}/update_scientific`,body);

      let data = response.data;
      
      return Promise.resolve({
        data
            });
    } catch (err) {
      console.error('[Update Scientific Profile Api]: ', err);
      throw new Error(err.response.data.message);
    }

  }
  
  async updateSocial(request)
  {
    const {linkedin_url, google_scholar_url,researchgate_url,scopus_url} = request;
    try {
      // Send a POST request to the server to authenticate the user
      let body = {
        linkedin_url,
        google_scholar_url,
        researchgate_url,
        scopus_url
      };
    

      const response = await axios.put(`${ENDPOINT}/update_social`,body);

      let data = response.data;
      
      return Promise.resolve({
        data
            });
    } catch (err) {
      console.error('[Update Social Profile Api]: ', err);
      throw new Error(err.response.data.message);
    }

  }
}

export const usersApi = new UsersApi();
