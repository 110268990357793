import { subHours, subMinutes } from 'date-fns';
import Users03Icon from '@untitled-ui/icons-react/build/esm/Users03';
import { IconButton, SvgIcon, Tooltip ,Badge} from '@mui/material';
import { usePopover } from 'src/hooks/use-popover';
import { RequestsPopover } from './requests-popover';
import { useTranslation } from 'react-i18next';
import { tokens } from 'src/locales/tokens';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import { useMounted } from 'src/hooks/use-mounted';
import { postsApi } from 'src/api/posts';
import { useCallback, useEffect, useState } from 'react';

const useRequests = (type) => {
  const isMounted = useMounted();
  const [requests, setRequests] = useState([]);
  const [loading, setLoading] = useState(false);
  const handleRequestsGet = useCallback(async () => {
    try {
      setLoading(true);
      const {data} = await postsApi.getRequests({type});

      if (isMounted()) {
        setRequests(data);
      }
    } catch (err) {
      console.error(err);
    }
    setLoading(false);

  }, [isMounted]);

  useEffect(() => {
      handleRequestsGet();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []);

  return {
    handleRequestsGet,
    requests,
    loading
  };
};

export const RequestsButton = (props) => {
  const {type = 'sent_requests'} = props;
  
  const popover = usePopover();
  const {t} = useTranslation();

  const {handleRequestsGet,requests,loading} = useRequests(type);

  return (
    <>
      <Tooltip title={t(tokens.labels[type])}>
        <IconButton
          onClick={async ()=>{
            popover.handleOpen()
            await handleRequestsGet();
          }}
          ref={popover.anchorRef}
        >
           <Badge
            color="error"
            badgeContent={requests.length}
          >
            <SvgIcon>
            {
              type === 'sent_requests' ? <KeyboardDoubleArrowUpIcon /> : <KeyboardDoubleArrowDownIcon />
            }
          </SvgIcon>
          </Badge>
         
        </IconButton>
      </Tooltip>
      <RequestsPopover
        anchorEl={popover.anchorRef.current}
        requests={requests}
        onClose={popover.handleClose}
        open={popover.open}
        loading={loading}
        label={t(tokens.labels[type])}
      />
    </>
  );
};
