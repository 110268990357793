import {  researcher } from './colors';

export const getPrimary = (preset) => {
  switch (preset) {
    case 'researcher':
      return researcher;
    default:
      console.error('Invalid color preset, accepted values: "blue", "green", "indigo" or "purple"".');
      return researcher;
  }
};
