import { tokens } from '../tokens';

export const ar = {
  [tokens.common.languageChanged]: 'تم تغيير اللغة',
  [tokens.nav.academy]: 'Academy',
  [tokens.nav.account]: 'الحساب',
  [tokens.nav.analytics]: 'Analytics',
  [tokens.nav.auth]: 'المصادقة',
  [tokens.nav.blog]: 'Blog',
  [tokens.nav.browse]: 'Browse',
  [tokens.nav.calendar]: 'Calendar',
  [tokens.nav.chat]: 'Chat',
  [tokens.nav.checkout]: 'Checkout',
  [tokens.nav.concepts]: 'Concepts',
  [tokens.nav.contact]: 'الاسماء',
  [tokens.nav.course]: 'Course',
  [tokens.nav.create]: 'انشاء',
  [tokens.nav.crypto]: 'Crypto',
  [tokens.nav.customers]: 'Customers',
  [tokens.nav.dashboard]: 'لوحة التحكم',
  [tokens.nav.details]: 'التفاصيل',
  [tokens.nav.ecommerce]: 'E-Commerce',
  [tokens.nav.edit]: 'تعديل',
  [tokens.nav.error]: 'خطأ',
  [tokens.nav.feed]: 'المنشورات',
  [tokens.nav.feeds]: 'المنشورات',
  [tokens.nav.fileManager]: 'File Manager',
  [tokens.nav.finance]: 'المالية',
  [tokens.nav.fleet]: 'Fleet',
  [tokens.nav.forgotPassword]: 'Forgot Password',
  [tokens.nav.invoiceList]: 'Invoices',
  [tokens.nav.jobList]: 'Job Listings',
  [tokens.nav.kanban]: 'Kanban',
  [tokens.nav.list]: 'القائمة',
  [tokens.nav.logistics]: 'Logistics',
  [tokens.nav.mail]: 'Mail',
  [tokens.nav.management]: 'الادارة',
  [tokens.nav.orderList]: 'Orders',
  [tokens.nav.overview]: 'Overview',
  [tokens.nav.pages]: 'الصفحات',
  [tokens.nav.postCreate]: 'Post Create',
  [tokens.nav.postDetails]: 'Post Details',
  [tokens.nav.postList]: 'Post List',
  [tokens.nav.pricing]: 'Pricing',
  [tokens.nav.productList]: 'Products',
  [tokens.nav.profile]: 'Profile',
  [tokens.nav.register]: 'تسحيل',
  [tokens.nav.resetPassword]: 'Reset Password',
  [tokens.nav.socialMedia]: 'Social Media',
  [tokens.nav.verifyCode]: 'تأكيد الرمز',
  [tokens.nav.filters]: 'تصفية',



  // labels
  [tokens.labels.certificates] : 'الشهادات',
  
  [tokens.labels.fields_of_interest]: 'مجالات الاهتمام',
  [tokens.labels.login]: 'تسجيل الدخول',
  [tokens.labels.userName]: 'اسم المستخدم',
  [tokens.labels.password]: 'كلمة المرور',
  [tokens.labels.profile]:'الصفحة الشخصية',
  [tokens.labels.settings]:'الاعدادات',
  [tokens.labels.theme]:'الثيم',
  [tokens.labels.logout]:'تسجيل الخروج',
  [tokens.labels.verification]:'تفعيل الحساب',
  [tokens.labels.verification_about_step1]:'نبذة',
  [tokens.labels.verification_tc_step2]:'الشروط والاحكام',
  [tokens.labels.verification_basic_details_step3]:'البيانات الاساسية',
  [tokens.labels.verification_address_step4]:'العنوان',
  [tokens.labels.verification_kyc_step5]:'المستمسكات',

  [tokens.labels.verification_entity_step]:'النشاطات التجارية',
  [tokens.labels.financial_information_step]:'المعلومات المالية',
  [tokens.labels.submitting_step]:'ارسال البيانات',

  [tokens.labels.continue]:'التالي',
  [tokens.labels.back]:'رجوع',
  [tokens.labels.tc]:'الشروط والاحكام',  
  [tokens.labels.show]:'عرص',  
  [tokens.labels.delete]:'حذف',  
  [tokens.labels.uploaded_docs]:'المرفقات المرفوعة',
  [tokens.labels.close]:'غلق',
  [tokens.labels.download_data]:'تحميل البيانات',
  [tokens.labels.download_as_pdf]:'تحميل كـ PDF',
  [tokens.labels.accounts]:'الحسابات',
  [tokens.labels.list]:'القائمة',
  [tokens.labels.add]:'اضافة',
  [tokens.labels.fullName]:'الاسم الكامل',

  [tokens.labels.dob]:'تاريخ الميلاد',
  [tokens.labels.account_status]:'حالة الحساب',
  [tokens.labels.user_address]:'العنوان',
  [tokens.labels.zmc_code]:'رمز ZMC',
  [tokens.labels.entity]:'النشاط التجاري',
  [tokens.labels.account_verified_name]:'حالة الحساب',
  [tokens.labels.approved]:'الموافقة',
  [tokens.labels.approval_date]:'تاريخ الموافقة',
  [tokens.labels.actions]:'الاجراءات',
  [tokens.labels.search]:'البحث',
  [tokens.labels.accounts]:'الحسابات',
  [tokens.labels.ar_name]:'الاسم العربي',
  [tokens.labels.en_name]:'الاسم الانجليزي',
  [tokens.labels.phone_number]:'رقم الهاتف',
  [tokens.labels.filter]:'تصفية',
  [tokens.labels.reset]:'تصفير',
  [tokens.labels.cities]:'المدن',
  [tokens.labels.status]:'الحالة',
  [tokens.labels.veriosn_number]:'النسخة',
  [tokens.labels.provinances]:'المحافظات',
  [tokens.labels.is_approved]:'هل تمت الموافقة',
  [tokens.labels.generalFilter]:'كلمة البحث',
  [tokens.labels.approveNotes]:'ملاحظات الموافقة',
  [tokens.labels.confirm]:'تأكيد',
  [tokens.labels.cancel]:'الغاء',
  [tokens.labels.save]:'حفظ',
  [tokens.labels.edit]:'تعديل',
  [tokens.labels.generalfilter]:'كلمة البحث',

  //actions
  [tokens.labels.approve]:'موافقة',
  [tokens.labels.verify]:'توثيق',
  [tokens.labels.archive]:'ارشفة',
  [tokens.labels.unarchive]:'الغاء الارشفة',
  [tokens.labels.update_required]:'مطلوب تحديث',
  [tokens.labels.account_type]:'نوع الحساب',
  [tokens.labels.supervisedBY]:'الاشراف من قبل',

  [tokens.labels.first_name]:'الاسم الاول',
  [tokens.labels.second_name]:'الاسم الثاني',
  [tokens.labels.third_name]:'الاسم الثالث',
  [tokens.labels.last_name]:'الاسم الاخير',
  [tokens.labels.secondary_phone_number]:'رقم الهاتف الثانوي',
  [tokens.labels.back]:'رجوع',
  [tokens.labels.continue]:'التالي',
  [tokens.labels.pob]:'مكان الولادة',
  [tokens.labels.dob]:'تاريخ الولادة',
  [tokens.labels.email]:'عنوان البريد الالكتروني',
  [tokens.labels.english]:'الانكليزي',
  [tokens.labels.arabic]:'العربي',
  [tokens.labels.view]:'عرض',

  [tokens.labels.monthly_income]:'الدخل الشهري',
  [tokens.labels.personal_saving]:'التوفير الشخصي',
  [tokens.labels.bussiness_incode]:'الدخل التجاري',
  [tokens.labels.other_income]:'دخل اخر',
  [tokens.labels.other_income_source]:'مصدر الدخل الاخر',


  [tokens.labels.country]:'الدولة',
  [tokens.labels.city]:'المدينة',
  [tokens.labels.second_address]:'العنوان الثانوي',
  [tokens.labels.type_of_residence]:'نوع السكن',
  [tokens.labels.sales_volume]:'حجم المبيعات',

  [tokens.labels.max_monthly_volume]:'الحجم الشهري الاقصى',
  [tokens.labels.doe]:'تاريخ التأسيس',
  [tokens.labels.goods_catogaries]:'اصناف البضائع',
  [tokens.labels.supported_docs_types]:'المستمسكات المطلوبة',
  [tokens.labels.add_staff_user]:'اضافة مستخدم طاقم عمل',
  [tokens.labels.add_other_user]:'اضافة مستخدم اخر',
  [tokens.labels.nationality]:'القومية',
  [tokens.labels.national_id]:'رقم البطاقة الوطنية',
  [tokens.labels.passport_id]:'رقم الجواز',
  [tokens.labels.other_nationality]:'القومية الاخرى',
  [tokens.labels.supported_docs_types]:'المستمسكات',

  [tokens.labels.entity_name]:'اسم النشاط',
  [tokens.labels.paid_in_capital]:'رأس المال المدفوع',
  [tokens.labels.currency]:'العملة',
  [tokens.labels.shared_holders]:'الشركاء',
  [tokens.labels.sales_volume]:'حجم المبيعات',
  [tokens.labels.monthly_sales_volume]:'حجم المبيعات الشهري',
  [tokens.labels.annually_sales_volume]:'حجم المبيعات السنوي',
  [tokens.labels.max_sales_volume]:'اقصى حد لحجم المبيعات',
  [tokens.labels.submit]:'ارسال',


  [tokens.labels.sent_requests]: 'الطلبات المرسلة',
  [tokens.labels.incoming_requests]: 'الطلبات المستلمة',
  [tokens.labels.join]: 'طلب انضمام',
  [tokens.labels.cancel]: 'الغاء',
  [tokens.labels.accept]: 'وافق',
  [tokens.labels.reject]: 'رفض',
  [tokens.labels.leave]: 'مغادرة',
  [tokens.labels.joiners]: 'المنضمين',
  [tokens.labels.created_at]: 'تاريخ النشر',
  [tokens.labels.createPost]: 'اضافة منشور',
  [tokens.labels.your_recent_posts]: 'المنشورات الحديثة',
  [tokens.labels.register]: 'تسجيل',
  [tokens.labels.email]: 'البريد الالكتروني',
  [tokens.labels.password]: 'كلمة المرور',
  [tokens.labels.my_posts]:'منشوراتي',
  [tokens.labels.accountDetails]:'معلومات الحساب',

  [tokens.labels.account_verification]:'التحقق وتفعيل الحساب',
  [tokens.labels.account_completion]:'انهاء التسجيل',

  [tokens.labels.fill_your_name]:'ادخل الاسم',
  [tokens.labels.university]:'الجامعة',
  [tokens.labels.college]:'الكلية',
  [tokens.labels.academic_title]:'العنوان الاكاديمي',
  [tokens.labels.male]:'ذكر',
  [tokens.labels.female]:'انثى',
  [tokens.labels.verify]:'تحقق',
  [tokens.labels.verification_code]:'رمز التحقق',
  [tokens.labels.search]:'بحث',
  [tokens.labels.apply_filter]:'تطبيق التصفية',
  [tokens.labels.open]:'متاح',
  [tokens.labels.close]:'مغلق',
  [tokens.labels.fields]:'المجالات',
  [tokens.labels.vis_type]:'الخصوصية',
  [tokens.labels.public]:'عام',
  [tokens.labels.private]:'خاص',
  [tokens.labels.status]:'الحالة',
  [tokens.labels.filter]:'التصفية',
  [tokens.labels.profile]:'الصفحة الشخصية',
  [tokens.labels.settings]:'الاعدادات',
  [tokens.labels.save]:'حفظ',
  [tokens.labels.edit]: 'تعديل',
  [tokens.labels.logout]: 'تسجيل خروج',
  [tokens.labels.change_password]: 'تغيير كلمة المرور',
  [tokens.labels.old_password]: 'كلمة المرور الحالية',
  [tokens.labels.new_password]: 'كلمة المرور الجديدة',
  [tokens.labels.loading]: 'جاري التحديث',
  [tokens.labels.ago]: 'منذ',
  [tokens.labels.see_less]: 'القليل',
  [tokens.labels.see_more]: 'المزيد',
  [tokens.labels.private]: 'خاص',
  [tokens.labels.public]: 'عام',
  [tokens.labels.all]: 'الكل',
  [tokens.labels.profile_updated]: 'تم تحديث الحساب',
  [tokens.labels.search_for_user]: 'استخدام علامة الـ @ قبل اسم المستخدم',
  [tokens.labels.status]: 'حالة النشر',
  [tokens.labels.current_invitations]: 'الدعوات الحالية',
  [tokens.labels.removed_users]: 'المستخدمين المحذوفين',
  [tokens.labels.appended_users]: 'المستخدمين المضافين',
  [tokens.labels.or_open]: 'او عرض',
  [tokens.labels.certificates_list]: 'قائمة الشهادات',
  [tokens.labels.add]: 'اضافة',
  [tokens.labels.fill_required_information]: 'ادخل المعلومات المطلوبة',
  [tokens.labels.title]: 'العنوان',
  
  // actions
  [tokens.actions.not_joined]: 'طلب انضمام',
  [tokens.actions.joined_waiting_owner]: 'تم ارسال الطلب',
  [tokens.actions.joined_accepted]: 'تم قبول الطلب',
  [tokens.actions.joined_rejected]: 'تم رفض الطلب',
  [tokens.actions.joined_waiting_me]: 'وافق',


  // msgs
  [tokens.msg.here_you_can_manage_your_recent_posts]: 'هنا يمكنك ادارة منشوراتك',
  [tokens.msg.hello]: 'مرحبا',
  [tokens.msg.do_not_have_account]: 'ليس لديك حساب؟',
  // Messages Section
  [tokens.msg.changing]:'جاري تغيير اللغة...',  
  [tokens.msg.i_have_read]:'لقد قرأت ووافقت على كافة',  
  [tokens.msg.terms_and_conditions]:'الاحكام الشروط',  

  [tokens.msg.username_required]:'اسم المستخدم مطلوب',  
  [tokens.msg.password_required]:'كلمة المرور مطلوبة',  
  [tokens.msg.all_done]:'لقد تمت العملية بنجاح',  
  [tokens.msg.here_is_preview_account]:'لقد استلمنا بياناتك بنجاح, سوف يتم مراجعتها واشعارك قريبا, شكرا لك.',  
  [tokens.msg.no_filter_applied]:'لا يوجد تصفية مطبقة',  
  [tokens.msg.confirmation]:'رسالة تأكيد',  
  [tokens.msg.change_status_confirm]:'هل انت متأكد من هذا الاجراء؟',  
  [tokens.msg.please_put_your_notes]:'يرجى كتابة ملاحظاتك',  
  [tokens.msg.here_is_what_connection_posted]:'اخر المنشورات التي تم نشرها مؤخراً',  
  [tokens.msg.please_use_a_new_password]:'لا يمكنك استخدام كلمة مرور قديمة',  
  [tokens.msg.password_changed_successfully]:'تم تغيير كلمة المرور بنجاح',  

    // Backend Keys Messages Trans
    [tokens.msg.access_approved]:'تم تسجيل الدخول بنجاح',
    [tokens.msg.missmatch_data]:'فشل تسجيل الدخول',
    [tokens.msg.access_denied]:'دخول غير مصرح به',
    [tokens.msg.not_allowd_use_service]:'غير مصرح لاستخدام الخدمة',
    [tokens.msg.merchant_need_register]:'يرجى اكمال تأكيد بيانات حسابك',
    [tokens.msg.change_suceess]:'تم تغيير اللغة بنجاح',
    [tokens.msg.change_failed]:'فشل تغيير اللغة',
    [tokens.msg.call_admin]:'خطأ في اعادة تعيين كلمة المرور, يرجى التواصل مع المسؤول',
    [tokens.msg.sql_error]:'هنالك مشكلة ما, يرجى المحاولة مرة اخرى',
    [tokens.msg.not_exist_data]:'لا يوجد مستخدم بهذا الاسم',
    [tokens.msg.missing_info]:'خطأ, بعض البيانات المطلوبة ناقصة',
    [tokens.msg.avilable_to_use]:'هذا المستخدم متاح',
    [tokens.msg.not_avilable]:'لا يمكن استخدام هذا المستخدم, غير متاح',
    [tokens.msg.fetch_success]:'عملية ناجحة',
    [tokens.msg.data_retrive_error]:'مشكلة في استرجاع البيانات',
    [tokens.msg.exist_data]:'خطأ, البيانات موجودة بالفعل',
    [tokens.msg.are_your_sure_to_submit]:'  هل انت متأكد من ارسال البيانات؟',  

    [tokens.msg.published_research_post]:'نشر منشور بحث',  
    [tokens.msg.profile_updated]: 'تم تحديث بيانات الحساب',
    [tokens.msg.join_the_contributors_list]: 'التحق بقائمة المساهمين',
    [tokens.msg.join_the_contributors_list_description]: 'ارسل طلب للأنضمام الى قائمة المساهمين',


      // validations
[tokens.val.cert_required]:'الشهادة مطلوبة',
[tokens.val.first_name_required]:'الاسم الاول مطلوب',
[tokens.val.second_namee_required]:'الاسم الثاني مطلوب',
[tokens.val.third_name_required]:'الاسم الثالث مطلوب',
[tokens.val.last_name_required]:'الاسم الاخير مطلوب',

[tokens.val.invalid_email]:'بريد الكتروني غير صحيح',
[tokens.val.dob_is_required]:'تاريخ الولادة مطلوب',
[tokens.val.pob_required]:'محل الولادة مطلوب',
[tokens.val.choose_country]:'اختر الدولة',
[tokens.val.choose_city]:'اختر المدينة',
[tokens.val.tor_required]:'اختر نوع السكن',

[tokens.val.choose_nationality]:'اختر القومية',
[tokens.val.nationality_required_when_iraq]:'ادخل رقم البطاقة الوطنية',
[tokens.val.passport_is_required]:'ادخل رقم الجواز',

[tokens.val.entity_name_is_required]:'اسم النشاط مطلوب',
[tokens.val.address_is_required]:'العنوان مطلوب',
[tokens.val.activity_type_is_required]:'نوع النشاط مطلوب',
[tokens.val.currency_is_required]:'العملة مطلوبة',
[tokens.val.doe_required]:'تاريخ التأسيس',
[tokens.val.choose_provinance]:'أختر المحافظة',
[tokens.val.choose_at_least_one_category]:'اختر على الاقل صنف واحد',
[tokens.val.choose_category]:'أختر صنف',
};
