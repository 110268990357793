import { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import SearchMdIcon from '@untitled-ui/icons-react/build/esm/SearchMd';
import XIcon from '@untitled-ui/icons-react/build/esm/X';
import {
  Badge,
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  Divider,
  IconButton,
  InputAdornment,
  Stack,
  SvgIcon,
  TextField,
  Typography,
  Avatar,
  Link
} from '@mui/material';
import { Tip } from 'src/components/tip';
import { wait } from 'src/utils/wait';
import { useTranslation } from 'react-i18next';
import { tokens } from 'src/locales/tokens';
import { usersApi } from 'src/api/users';
import { toast } from 'react-hot-toast';
import { users } from 'src/api/auth/data';
import { getFullURL } from 'src/utils/gen-image-url';
import { paths } from 'src/paths';
const articles = {
  Platform: [
    {
      description: 'Provide your users with the content they need, exactly when they need it, by building a next-level site search experience using our AI-powered search API.',
      title: 'Level up your site search experience with our hosted API',
      path: 'Users / Api-usage'
    },
    {
      description: 'Algolia is a search-as-a-service API that helps marketplaces build performant search experiences at scale while reducing engineering time.',
      title: 'Build performant marketplace search at scale',
      path: 'Users / Api-usage'
    }
  ],
  Resources: [
    {
      description: 'Algolia’s architecture is heavily redundant, hosting every application on …',
      title: 'Using NetInfo API to Improve Algolia’s JavaScript Client',
      path: 'Resources / Blog posts'
    },
    {
      description: 'Algolia is a search-as-a-service API that helps marketplaces build performant search experiences at scale while reducing engineering time.',
      title: 'Build performance',
      path: 'Resources / UI libraries'
    }
  ]
};

export const SearchDialog = (props) => {
  const { onClose, open = false, ...other } = props;
  const [value, setValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [usersList, setusersList] = useState({
    users:[]
  });

  console.log(usersList)
  const {t} = useTranslation();
  const handleSubmit = useCallback(async (event) => {
    event.preventDefault();
    setusersList({
      users:[]
    
    });
    setIsLoading(true);
    // Do search here
    try {
      const {data} = await usersApi.search(value);
      console.log(data)
      const usersListTemp = {
        users:[...data]
      }
      setusersList(usersListTemp)
    } catch (error) {
      console.error(error)
      toast.error(error.message)
    }
    // await wait(1500);
    setIsLoading(false);
  }, []);

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      onClose={onClose}
      open={open}
      {...other}>
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        spacing={3}
        sx={{
          px: 3,
          py: 2
        }}
      >
        <Typography variant="h6">
        {t(tokens.labels.search_for_user)}
        </Typography>
        <IconButton
          color="inherit"
          onClick={onClose}
        >
          <SvgIcon>
            <XIcon />
          </SvgIcon>
        </IconButton>
      </Stack>
      <DialogContent>
        <Tip message={t(tokens.msg.search_tip_1)} />
        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{ mt: 3 }}
        >
          <TextField
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SvgIcon>
                    <SearchMdIcon />
                  </SvgIcon>
                </InputAdornment>
              )
            }}
            label={t(tokens.labels.search)}
            onChange={(event) => setValue(event.target.value)}
            placeholder={t(tokens.labels.search)}
            value={value}
          />
        </Box>
        {isLoading && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              mt: 3
            }}
          >
            <CircularProgress />
          </Box>
        )}
        {usersList && (
          <Stack
            spacing={2}
            sx={{ mt: 3 }}
          >
            {Object.keys(usersList).map((type, index) => (
              <Stack
                key={index}
                spacing={2}
              >
                <Typography variant="h6">
                  {type}
                </Typography>
                <Stack
                  divider={<Divider />}
                  sx={{
                    borderColor: 'divider',
                    borderRadius: 1,
                    borderStyle: 'solid',
                    borderWidth: 1
                  }}
                >
                  {usersList[type].map((user, index) => (
                    <Box
                      component={Link}
                      href={paths.dashboard.social.profile.replace(':userId', user.user_info.user_id)}
                      key={user.user_info.user_id}
                      sx={{ p: 2 }}
                    >
                      <Stack
                        alignItems="center"
                        direction="row"
                        spacing={2}
                        sx={{ pl: 1 }}
                      >
                        <Avatar
                        src={getFullURL(user.user_info.img_url)}
                        />
                        <Typography variant="subtitle1">
                          {`${user.user_info.first_name} ${user.user_info.last_name}`}
                        </Typography>
                      </Stack>
                      <Typography
                        color="text.secondary"
                        variant="body2"
                      >
                       {`${user.user_info.university} ${user.user_info.colleage}`}
                      </Typography>
                      <Typography
                        color="text.secondary"
                        variant="body2"
                        sx={{ mt: 1 }}
                      >
                        {user.user_info.academic_title}
                      </Typography>
                    </Box>
                  ))}
                </Stack>
              </Stack>
            ))}
          </Stack>
        )}
      </DialogContent>
    </Dialog>
  );
};

SearchDialog.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool
};
