import { createSlice } from '@reduxjs/toolkit';

export const SLICE_NAME = 'resourcesList';

const initialState = {
  countries:[],
  academicTitles: [],
  fields:[],
  // certificates:[]
};

const reducers = {

  getResourcesList(state, action) {
     return action.payload;
  } 
};

export const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers
});

export const { reducer } = slice;
