import { ACTION_ACCEPT, ACTION_ACCEPT_REJECT, ACTION_CANCEL, ACTION_JOIN, ACTION_REJECT, NO_ACTION } from 'src/constants';
import myAxios from '../my-axios';


const ENDPOINT = '/posts';
class PostsApi {
  async create(request) {
    const { title, abstract,type,fields, users=[] } = request;
    
    try {
      // Send a POST request to the server to authenticate the user
      const response = await myAxios.post(`${ENDPOINT}`,{ 
        title, abstract,type,fields, users
      });
      const  data  = response.data.data;
    
      return data;
    } catch (err) {
      console.error('[Create post Api]: ', err);
     
      throw new Error(err.response.data.message);
    }
  }

  async getAll(request = {}) {

    const {filters,page,rowsPerPage} = request;
    try {
      let url = ENDPOINT
      
      url = url +"?page="+(page)+"&limit="+rowsPerPage;

      if(filters && typeof filters.query !== 'undefined' && filters.query !== ''){
        url = url + "&query="+filters.query;
      }
      if(filters && typeof filters.fields !== 'undefined' && filters.fields.length > 0){
        const fields = filters.fields.join(',');
        url = url + "&fields="+fields;
      }

      if(filters && typeof filters.visibility !== 'undefined' && filters.visibility !== 'all'){
        url = url + "&visibility="+filters.visibility;
      }

      if(filters && typeof filters.status !== 'undefined' && filters.status !== 'all'){
        url = url + "&status="+filters.status;
      }
    
      // Send a POST request to the server to authenticate the user
      const response = await myAxios.get(url);
  

      let data = response.data.data.list;
      let count = response.data.data.total;
      
      return Promise.resolve({
        data,
        count
      });
    } catch (err) {
      console.error('[Get Posts Api]: ', err);
      throw new Error(err.response.data.message);
    }

  }

  async getMyPosts(request = {}) {
    const {page, rowsPerPage} = request;
    try {
      let url = ENDPOINT+`/myPosts`
      
      url = url +"?page="+(page)+"&limit="+rowsPerPage;

      // Send a POST request to the server to authenticate the user
      const response = await myAxios.get(url);
  

      let data = response.data.data.list;
      let count = response.data.data.total;
      
      return Promise.resolve({
        data,
        count
      });
    } catch (err) {
      console.error('[Get My Posts Api]: ', err);
      throw new Error(err.response.data.message);
    }

  }

  async getRequests(request)
  {
    const {type='sent_requests'} = request;
    let routeName = type === 'sent_requests'? 'myRequests' : 'inCommingRequests';
    try {
      let url = `${ENDPOINT}/${routeName}`
      
      // Send a POST request to the server to authenticate the user
      const response = await myAxios.get(url);
  

      let data = response.data.data.list;
      let count = response.data.data.total;
      
      return Promise.resolve({
        data,
        count
      });
    } catch (err) {
      console.error('[Get requests Api]: ', err);
      throw new Error(err.response.data.message);
    }
  }
  async handlePostAction(request)
  {
    const {post, action}  = request;
    let url = ENDPOINT;
    try {
      let url = `${ENDPOINT}/${post.id}`
      let response = null;
      switch(action)
      {
        case ACTION_JOIN:
          url = url + `/assignMyself`;
          response = await myAxios.put(url);
         
          break;
        case ACTION_CANCEL:
          url = url + `/assignMyself`;
          response = await myAxios.delete(url);

          break;
        case ACTION_ACCEPT:
          url = url + `/requests/approve`;
          response = await myAxios.put(url);

          break;
        case ACTION_REJECT:
          url = url + `/requests/reject`;
          response = await myAxios.put(url);

          break;
        case NO_ACTION:
            // nothing to do
            break;
      }
      // Send a POST request to the server to authenticate the user
      

      let data = response?.data?.data;
      
      return Promise.resolve({
        data
      });
    } catch (err) {
      console.error('[Request Action Api]: ', err);
      throw new Error(err.response.data.message);
    }
    
  }

  async get(request)
  {
    const {id, action}  = request;
    let url = ENDPOINT;
    try {
      let url = `${ENDPOINT}/${id}`
      let response = await myAxios.get(url);
      
      let data = response?.data?.data.list[0];
      
      return Promise.resolve({
        data
      });
    } catch (err) {
      console.error('[get Post Api]: ', err);
      throw new Error(err.response.data.message);
    }
    
  }
  async update(request) {
    const { id,title, abstract,type,fields,status } = request;
    
    try {
      // Send a POST request to the server to authenticate the user
      const response = await myAxios.put(`${ENDPOINT}/${id}`,{ 
        title, abstract,type,fields, status
      });
      const  data  = response.data.data;
    
      return {data};
    } catch (err) {
      console.error('[Update post Api]: ', err);
     
      throw new Error(err.response.data.message);
    }
  }

  async modifyInvitations(request) {
    const { id,appended,removed } = request;
    
    try {
      // Send a POST request to the server to authenticate the user
      const response = await myAxios.put(`${ENDPOINT}/${id}/invitations/update`,{ 
        appended,removed
      });
      const  data  = response.data.data;
    
      return {data};
    } catch (err) {
      console.error('[Modify Invitation Api]: ', err);
     
      throw new Error(err.response.data.message);
    }
  }

}

export const postsApi = new PostsApi();
