import { createContext, useCallback, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import { authApi } from 'src/api/auth';
import { Issuer } from 'src/utils/auth';

const STORAGE_KEY_USER_DATA = 'researchers-app';

var ActionType;
(function (ActionType) {
  ActionType['INITIALIZE'] = 'INITIALIZE';
  ActionType['SIGN_IN'] = 'SIGN_IN';
  ActionType['SIGN_UP'] = 'SIGN_UP';
  ActionType['SIGN_OUT'] = 'SIGN_OUT';
})(ActionType || (ActionType = {}));

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user
    };
  },
  SIGN_IN: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  },
  SIGN_UP: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  },
  SIGN_OUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null
  })
};

const reducer = (state, action) => (handlers[action.type]
  ? handlers[action.type](state, action)
  : state);

export const AuthContext = createContext({
  ...initialState,
  issuer: Issuer.JWT,
  signIn: () => Promise.resolve(),
  signUp: () => Promise.resolve(),
  signOut: () => Promise.resolve(),
  forgotPassword: () => Promise.resolve(),
  resetPassword: () => Promise.resolve(),
  updateUserData: () => Promise.resolve()

});

export const AuthProvider = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);

  const initialize = useCallback(async () => {
    try {
      const userData = JSON.parse(window.localStorage.getItem(STORAGE_KEY_USER_DATA));

      if (userData && userData.token) {
        dispatch({
          type: ActionType.INITIALIZE,
          payload: {
            isAuthenticated: true,
            user:userData
          }
        });
      } else {
        dispatch({
          type: ActionType.INITIALIZE,
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    } catch (err) {
      console.error(err);
      dispatch({
        type: ActionType.INITIALIZE,
        payload: {
          isAuthenticated: false,
          user: null
        }
      });
    }
  }, [dispatch]);

  useEffect(() => {
      initialize();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []);

    const signIn = useCallback(async (username, password) => {
    const { userData } = await authApi.signIn({ username, password });
      // const user = await authApi.me({ accessToken , userData});
      localStorage.setItem(STORAGE_KEY_USER_DATA, JSON.stringify(userData));
    
        dispatch({
          type: ActionType.SIGN_IN,
          payload: {
            user:userData
          }
        });
    }, [dispatch]);

    
    const updateUserData =async (data) => {
    
        // const user = await authApi.me({ accessToken , userData});
        let userData = JSON.parse(window.localStorage.getItem(STORAGE_KEY_USER_DATA));      
        // userData.user_info.img_url = data.img_url;
        Object.assign(userData,data);
        localStorage.setItem(STORAGE_KEY_USER_DATA, JSON.stringify(userData));

      };
    const forgotPassword = useCallback(async (username) => {
      await authApi.forgotPassword({ username });
      
      }, []);

      const resetPassword = useCallback(async (username,otp,new_password) => {
        await authApi.resetPassword({ username,otp,new_password });
        
        }, []);
  // const signUp = useCallback(async (email, name, password) => {
  //   const { accessToken } = await authApi.signUp({ email, name, password });
  //   const user = await authApi.me({ accessToken });

  //   sessionStorage.setItem(STORAGE_KEY, accessToken);

  //   dispatch({
  //     type: ActionType.SIGN_UP,
  //     payload: {
  //       user
  //     }
  //   });
  // }, [dispatch]);

  const signOut = useCallback(async () => {
    localStorage.removeItem(STORAGE_KEY_USER_DATA);
    dispatch({ type: ActionType.SIGN_OUT });
  }, [dispatch]);

  return (
    <AuthContext.Provider
      value={{
        ...state,
        issuer: Issuer.JWT,
        signIn,
        // signUp,
        forgotPassword,
        resetPassword,
        signOut,
        updateUserData
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export const AuthConsumer = AuthContext.Consumer;
