import PropTypes from 'prop-types';
import Settings03Icon from '@untitled-ui/icons-react/build/esm/Settings03';
import { Box, ButtonBase, SvgIcon, Tooltip,Link } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { tokens } from 'src/locales/tokens';
import AddIcon from '@mui/icons-material/Add';
import { paths } from 'src/paths';
import { useAuth } from 'src/hooks/use-auth';
export const FABNewPost = (props) => {
    
    const {user} =  useAuth();
   
    const {t} = useTranslation()

    if(!user)
    return null;
  
    return (
        <Tooltip title={t(tokens.nav.newPost)}>
          <Box
            sx={{
              backgroundColor: 'background.paper',
              borderRadius: '50%',
              bottom: 0,
              boxShadow: 16,
              margin: (theme) => theme.spacing(4),
              position: 'fixed',
              right: 0,
              zIndex: (theme) => theme.zIndex.speedDial
            }}
            {...props}>
            <ButtonBase
              sx={{
                backgroundColor: 'primary.main',
                borderRadius: '50%',
                color: 'primary.contrastText',
                p: '10px'
              }}
            component={Link}
              href={paths.dashboard.posts.create}
            >
              <SvgIcon>
                <AddIcon />
              </SvgIcon>
            </ButtonBase>
          </Box>
        </Tooltip>
      );
}

FABNewPost.propTypes = {
  onClick: PropTypes.func
};
