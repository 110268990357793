
export const tokens = {
  common: {
    languageChanged: 'common.languageChanged'
  },

  labels: {
    sent_requests: 'labels.sent_requests',
    incoming_requests: 'labels.incoming_requests',
    join: 'labels.join',
    cancel: 'labels.cancel',
    accept: 'labels.accept',
    reject: 'labels.reject',
    leave: 'labels.leave',
    joiners: 'labels.joiners',
    created_at: 'labels.created_at',
    createPost: 'labels.createPost',
    your_recent_posts: 'labels.your_recent_posts',
    login: 'labels.login',
    register: 'labels.register',
    email: 'labels.email',
    password: 'labels.Password',
    userName: 'labels.userName',
    password: 'labels.password',
    login: 'labels.login',
    profile: 'labels.profile',
    settings: 'labels.settings',
    theme: 'labels.theme',
    logout: 'labels.logout',
    verification: 'labels.verification',
    verification_about_step1: 'labels.verification_about_step1',
    verification_tc_step2: 'labels.verification_tc_step2',
    verification_basic_details_step3: 'labels.verification_basic_details_step3',
    verification_address_step4: 'labels.verification_address_step4',
    verification_address_step4: 'labels.verification_address_step4',
    verification_kyc_step5: 'labels.verification_kyc_step5',
    verification_entity_step: 'labels.verification_entity_step',
    submitting_step: 'labels.submitting_step',
    tc: 'labels.tc',
    continue: 'labels.continue',
    back: 'labels.back',
    financial_information_step: 'labels.financial_information_step',
    show: 'labels.show',
    delete: 'labels.delete',
    uploaded_docs: 'labels.uploaded_docs',
    download_data: 'labels.download_data',
    download_as_pdf: 'labels.download_as_pdf',
    accounts: 'labels.accounts',
    list: 'labels.list',
    add: 'labels.add',

    fullName: 'labels.fullName',
    dob: 'labels.dob',
    account_status: 'labels.account_status',
    user_address: 'labels.user_address',
    zmc_code: 'labels.zmc_code',
    entity: 'labels.entity',
    account_verified_name: 'labels.account_verified_name',
    approved: 'labels.approved',
    approval_date: 'labels.approval_date',
    actions: 'labels.actions',
    search: 'labels.search',
    accounts: 'labels.accounts',
    ar_name: 'labels.ar_name',
    en_name: 'labels.en_name',
    phone_number: 'labels.phone_number',
    filter: 'labels.filter',
    reset: 'labels.reset',
    cities: 'labels.cities',
    status: 'labels.status',
    veriosn_number: 'labels.veriosn_number',
    provinances: 'labels.provinances',
    is_approved: 'labels.is_approved',
    generalFilter: 'labels.generalFilter',
    approveNotes: 'labels.approveNotes',
    confirm: 'labels.confirm',
    cancel: 'labels.cancel',
    save: 'labels.save',
    edit: 'labels.edit',

    approve: 'labels.approve',
    archive: 'labels.archive',
    unarchive: 'labels.Unarchive',
    verify: 'labels.verify',
    update_required: 'labels.update_required',
    generalfilter: 'labels.generalfilter',
    account_type: 'labels.account_type',
    supervisedBY: 'labels.supervisedBY',

    first_name: 'labels.first_name',
    second_name: 'labels.second_name',
    third_name: 'labels.third_name',
    last_name: 'labels.last_name',

    secondary_phone_number: 'labels.secondary_phone_number',
    back: 'labels.back',
    continue: 'labels.continue',
    pob: 'labels.pob',
    dob: 'labels.dob',
    email: 'labels.email',
    english: 'labels.english',
    arabic: 'labels.arabic',
    view: 'labels.view',

    monthly_income: 'labels.monthly_income',
    personal_saving: 'labels.personal_saving',
    bussiness_incode: 'labels.bussiness_incode',
    other_income: 'labels.other_income',
    other_income_source: 'labels.other_income_source',

    country: 'labels.country',
    city: 'labels.city',
    second_address: 'labels.second_address',
    type_of_residence: 'labels.type_of_residence',

    sales_volume: 'labels.sales_volume',
    max_monthly_volume: 'labels.max_monthly_volume',
    doe: 'labels.doe',
    goods_catogaries: 'labels.goods_catogaries',
    supported_docs_types: 'labels.supported_docs_types',
    add_staff_user:'labels.add_staff_user',
    add_other_user:'labels.add_other_user',
    nationality:'labels.nationality',
    national_id:'labels.national_id',
    passport_id:'labels.passport_id',
    other_nationality:'labels.other_nationality',
    supported_docs_types:'labels.supported_docs_types',
    entity_name:'labels.entity_name',
    paid_in_capital:'labels.paid_in_capital',
    currency:'labels.currency',
    shared_holders:'labels.shared_holders',
    sales_volume:'labels.sales_volume',
    monthly_sales_volume:'labels.monthly_sales_volume',
    annually_sales_volume:'labels.annually_sales_volume',
    max_sales_volume:'labels.max_sales_volume',
    submit:'labels.submit',
    my_posts:'labels.my_posts',
    accountDetails:'labels.accountDetails',
    account_verification:'labels.account_verification',
    account_completion:'labels.account_completion',
    fill_your_name:'labels.fill_your_name',
    university:'labels.university',
    college:'labels.college',
    academic_title:'labels.academic_title',
    male:'labels.male',
    female:'labels.female',
    verification_code:'labels.verification_code',
    verify:'labels.verify',
    filter:'labels.filter',
    profile:'labels.profile',
    settings:'labels.settings',
    save:'labels.save',

    specific_specialization:'labels.specific_specialization',
    general_specialization:'labels.general_specialization',
    search:'labels.search',
    apply_filter:'labels.apply_filter',
    open:'labels.open',
    close:'labels.close',
    fields:'labels.fields',
    vis_type:'labels.vis_type',
    public:'labels.public',
    private:'labels.private',
    account:'labels.account',
    change_cover:'labels.change_cover',
    edit:'labels.edit',
    logout:'labels.logout',
    change_password:'labels.change_password',
    old_password:'labels.old_password',
    new_password:'labels.new_password',
    loading:'labels.loading',
    ago:'labels.ago',

    see_more:'labels.see_more',
    see_less:'labels.see_less',
    private:'labels.private',
    public:'labels.public',
    all:'labels.all',
    profile_updated:'labels.profile_updated',
    search_for_user:'labels.search_for_user',
    status:'labels.status',
    current_invitations:'labels.current_invitations',
    removed_users:'labels.removed_users',
    appended_users:'labels.appended_users',
    or_open:'labels.or_open',
    linkedin_url:'labels.linkedin_url',
    google_scholar_url:'labels.google_scholar_url',
    researchgate_url:'labels.researchgate_url',
    scopus_url:'labels.scopus_url',
    certificates_list:'labels.certificates_list',
    add:'labels.add',
    fill_required_information:'labels.fill_required_information',
    title:'labels.title',
    fields_of_interest:'labels.fields_of_interest',
    scientific:'labels.scientific',
    certificates:'labels.certificates',
  },
  actions:{
    not_joined: 'labels.not_joined',
    joined_waiting_owner: 'labels.joined_waiting_owner',
    joined_accepted: 'labels.joined_accepted',
    joined_rejected: 'labels.joined_rejected',
    joined_waiting_me: 'labels.joined_waiting_me',
    accept:'actions.accept',
    reject:'actions.reject',
  },

  msg:{
    hello:'msg.hello',
    here_you_can_manage_your_recent_posts:'msg.here_you_can_manage_your_recent_posts',
    do_not_have_account:'msg.do_not_have_account',
    changing:'msg.changing',
    i_have_read: 'msg.i_have_read',
    terms_and_conditions: 'msg.terms_and_conditiosn',
    username_required: 'msg.username_required',
    password_required: 'msg.password_required',
    all_done: 'msg.all_done',
    here_is_preview_account: 'msg.here_is_preview_account',
    no_filter_applied: 'msg.no_filter_applied',
    confirmation: 'msg.confirmation',
    change_status_confirm: 'msg.change_status_confirm',
    please_put_your_notes: 'msg.please_put_your_notes',
    here_is_what_connection_posted: 'msg.here_is_what_connection_posted',
    // code from the backend
    access_approved: 'msg.access_approved',
    missmatch_data: 'msg.missmatch_data',
    access_denied: 'msg.access_denied',
    not_allowd_use_service: 'msg.not_allowd_use_service',
    merchant_need_register: 'msg.merchant_need_register',
    change_suceess: 'msg.change_suceess',
    change_failed: 'msg.change_failed',
    call_admin: 'msg.call_admin',
    sql_error: 'msg.sql_error',
    not_exist_data: 'msg.not_exist_data',
    missing_info: 'msg.missing_info',
    avilable_to_use: 'msg.avilable_to_use',
    not_avilable: 'msg.not_avilable',
    fetch_success: 'msg.fetch_success',
    data_retrive_error: 'msg.data_retrive_error',
    exist_data: 'msg.exist_data',
    min_doc_required:'msg.min_doc_required',
    please_upload_selectd_docs:'msg.please_upload_selectd_docs',
    are_your_sure_to_submit:'labels.are_your_sure_to_submit',
    please_use_a_new_password:'labels.please_use_a_new_password',
    password_changed_successfully:'labels.password_changed_successfully',
    published_research_post:'labels.published_research_post',
    search_tip_1:'labels.search_tip_1',
    profile_updated:'msg.profile_updated',
    join_the_contributors_list:'msg.join_the_contributors_list',
    join_the_contributors_list_description:'msg.join_the_contributors_list_description',
  },

  val:{
    cert_required: 'val.cert_required',
    first_name_required:'val.first_name_required',
    second_namee_required:'val.second_namee_required',
    third_name_required:'val.third_name_required',
    last_name_required:'val.last_name_required',
    invalid_email:'val.invalid_email',
    dob_is_required:'val.dob_is_required',
    pob_required:'val.pob_required',
    choose_country:'val.choose_country',
    choose_city:'val.choose_city',
    tor_required:'val.tor_required',
    choose_nationality:'val.choose_nationality',
    nationality_required_when_iraq:'val.nationality_required_when_iraq',
    passport_is_required:'val.passport_is_required',
    entity_name_is_required:'val.entity_name_is_required',
    address_is_required:'val.address_is_required',
    activity_type_is_required:'val.activity_type_is_required',
    currency_is_required:'val.currency_is_required',
    doe_required:'val.doe_required',
    choose_provinance:'val.choose_provinance',
    choose_at_least_one_category:'val.choose_at_least_one_category',
    choose_category:'val.choose_category',
  },
  nav: {
    academy: 'nav.academy',
    account: 'nav.account',
    analytics: 'nav.analytics',
    auth: 'nav.auth',
    blog: 'nav.blog',
    browse: 'nav.browse',
    calendar: 'nav.calendar',
    chat: 'nav.chat',
    checkout: 'nav.checkout',
    concepts: 'nav.concepts',
    contact: 'nav.contact',
    course: 'nav.course',
    create: 'nav.create',
    crypto: 'nav.crypto',
    customers: 'nav.customers',
    dashboard: 'nav.dashboard',
    details: 'nav.details',
    ecommerce: 'nav.ecommerce',
    edit: 'nav.edit',
    error: 'nav.error',
    feed: 'nav.feed',
    fileManager: 'nav.fileManager',
    files: 'nav.files',
    finance: 'nav.finance',
    fleet: 'nav.fleet',
    forgotPassword: 'nav.forgotPassword',
    invoiceList: 'nav.invoices',
    jobList: 'nav.jobList',
    kanban: 'nav.kanban',
    list: 'nav.list',
    login: 'nav.login',
    logistics: 'nav.logistics',
    mail: 'nav.mail',
    management: 'nav.management',
    orderList: 'nav.orders',
    overview: 'nav.overview',
    pages: 'nav.pages',
    postCreate: 'nav.postCreate',
    postDetails: 'nav.postDetails',
    postList: 'nav.postList',
    pricing: 'nav.pricing',
    productList: 'nav.products',
    profile: 'nav.profile',
    register: 'nav.register',
    resetPassword: 'nav.resetPassword',
    socialMedia: 'nav.socialMedia',
    verifyCode: 'nav.verifyCode',
    newPost: 'nav.newPost',
    publish: 'nav.publish',
    posts:'nav.posts',
    create:'nav.create',
    cancel:'nav.cancel',
    save:'nav.save',
    hello:'nav.hello',
    basic_details:'nav.basic_details',
    post_cover:'nav.post_cover',
    remove_photo:'nav.remove_photo',
    abstract:'nav.abstract',
    meta:'nav.meta',
    abstract_title:'nav.abstract_title',
    visibility:'nav.visibility',
    invitation:'nav.invitation',
    private:'nav.private',
    public:'nav.public',
    users_inv:'nav.users_inv',
    fields:'nav.fields',
    feeds:'nav.feeds',
    filters:'nav.filters',
    fields_of_interest:'labels.fields_of_interest',
  }
};
