import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { en } from './translations/en';
import { ar } from './translations/ar';
import { STORAGE_KEY_SETTING } from 'src/constants';



const getLang = ()=>{
  const setting = localStorage.getItem(STORAGE_KEY_SETTING,'en');
  if(setting) {
    const value = JSON.parse(setting);
    return value.language;
  }
  return 'en';

}
export default i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: { translation: en },
      ar: { translation: ar },
    },
    lng: getLang(),
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false
    }
  });
