export const STORAGE_KEY_USER_DATA = 'researchers-app';
export const EXCLUDED_ENDPOINTS = [
  'users/create_temp',
  'user_validate',
  'users/create',
  'login',
  'resources'
];

export const LANG_EN = 'en';
export const LANG_AR = 'ar';
export const LANG_KU = 'ku';
export const BASE_URL = 'https://researchers.coeng.uobaghdad.edu.iq';
export const STORAGE_KEY_SETTING = 'researchers-app-settings';


export const JOIN_STATUS_NOT_JOINED = 'not_joined';
export const JOIN_STATUS_JOINED_WAITING_OWNER = 'joined_waiting_owner';
export const JOIN_STATUS_JOINED_ACCEPTED = 'joined_accepted';
export const JOIN_STATUS_JOINED_REJECTED = 'joined_rejected';
export const JOIN_STATUS_JOINED_WAITING_ME = 'joined_waiting_me';
export const JOIN_STATUS_NO_ACTION = 'no_action';
 
export const ACTION_JOIN = 'join';
export const ACTION_CANCEL = 'cancel_leave';
export const NO_ACTION = 'no_action';
export const ACTION_ACCEPT_REJECT = 'accept_reject';
export const ACTION_ACCEPT = 'accept';
export const ACTION_REJECT = 'reject';