import { tokens } from '../tokens';

export const en = {
  [tokens.common.languageChanged]: 'Language changed',
  [tokens.nav.academy]: 'Academy',
  [tokens.nav.account]: 'Account',
  [tokens.nav.analytics]: 'Analytics',
  [tokens.nav.auth]: 'Auth',
  [tokens.nav.blog]: 'Blog',
  [tokens.nav.browse]: 'Browse',
  [tokens.nav.calendar]: 'Calendar',
  [tokens.nav.chat]: 'Chat',
  [tokens.nav.checkout]: 'Checkout',
  [tokens.nav.concepts]: 'Concepts',
  [tokens.nav.contact]: 'Contact',
  [tokens.nav.course]: 'Course',
  [tokens.nav.create]: 'Create',
  [tokens.nav.crypto]: 'Crypto',
  [tokens.nav.customers]: 'Customers',
  [tokens.nav.dashboard]: 'Dashboard',
  [tokens.nav.details]: 'Details',
  [tokens.nav.ecommerce]: 'E-Commerce',
  [tokens.nav.edit]: 'Edit',
  [tokens.nav.error]: 'Error',
  [tokens.nav.feed]: 'Feed',
  [tokens.nav.fileManager]: 'File Manager',
  [tokens.nav.finance]: 'Finance',
  [tokens.nav.fleet]: 'Fleet',
  [tokens.nav.forgotPassword]: 'Forgot Password',
  [tokens.nav.invoiceList]: 'Invoices',
  [tokens.nav.jobList]: 'Job Listings',
  [tokens.nav.kanban]: 'Kanban',
  [tokens.nav.list]: 'List',
  [tokens.nav.login]: 'Login',
  [tokens.nav.logistics]: 'Logistics',
  [tokens.nav.mail]: 'Mail',
  [tokens.nav.management]: 'Management',
  [tokens.nav.orderList]: 'Orders',
  [tokens.nav.overview]: 'Overview',
  [tokens.nav.pages]: 'Pages',
  [tokens.nav.postCreate]: 'Post Create',
  [tokens.nav.postDetails]: 'Post Details',
  [tokens.nav.postList]: 'Post List',
  [tokens.nav.pricing]: 'Pricing',
  [tokens.nav.productList]: 'Products',
  [tokens.nav.profile]: 'Profile',
  [tokens.nav.register]: 'Register',
  [tokens.nav.resetPassword]: 'Reset Password',
  [tokens.nav.socialMedia]: 'Social Media',
  [tokens.nav.verifyCode]: 'Verify Code',
  [tokens.nav.newPost]: 'New Post',
  [tokens.nav.posts]: 'Posts',
  [tokens.nav.publish]: 'Publish',
  [tokens.nav.create]: 'Create',
  [tokens.nav.cancel]: 'Cancel',
  [tokens.nav.hello]: 'Hello',
  [tokens.nav.save]: 'Save',
  [tokens.nav.basic_details]: 'Details',
  [tokens.nav.post_cover]: 'Image Cover',
  [tokens.nav.remove_photo]: 'Remove Photo',
  [tokens.nav.abstract]: 'Abstract',
  [tokens.nav.meta]: 'Post Meta',
  [tokens.nav.abstract_title]: 'Abstract Title',
  [tokens.nav.visibility]: 'Visibility',
  [tokens.nav.public]: 'Public',
  [tokens.nav.private]: 'Private',
  [tokens.nav.invitation]: 'Invitation',
  [tokens.nav.users_inv]: 'Invited Users',
  [tokens.nav.fields]: 'Fields',
  [tokens.nav.feeds]: 'Feeds',
  [tokens.nav.filters]: 'Filters',



  // labels
  [tokens.labels.certificates] : 'Certificates',

  [tokens.labels.scientific] : 'Scientific',
  [tokens.labels.fields_of_interest]: 'Fields of Interest',
  [tokens.labels.sent_requests]: 'Sent Requests',
  [tokens.labels.incoming_requests]: 'Incoming Requests',
  [tokens.labels.join]: 'Join',
  [tokens.labels.cancel]: 'Cancel',
  [tokens.labels.accept]: 'Accept',
  [tokens.labels.reject]: 'Reject',
  [tokens.labels.leave]: 'Leave',
  [tokens.labels.joiners]: 'Joiners',
  [tokens.labels.created_at]: 'Created at',
  [tokens.labels.createPost]: 'Create Post',
  [tokens.labels.your_recent_posts]: 'Your Recent Posts',
  [tokens.labels.register]: 'Register',
  [tokens.labels.login]: 'Login',
  [tokens.labels.email]: 'Email',
  [tokens.labels.password]: 'Password',

  //actions
  [tokens.labels.approve]:'Approve',
  [tokens.labels.verify]:'Verify',
  [tokens.labels.archive]:'Archive',
  [tokens.labels.unarchive]:'Unarchive',
  [tokens.labels.update_required]:'Update Required',
  [tokens.labels.account_type]:'Account Type',
  [tokens.labels.supervisedBY]:'Supervised By',

  [tokens.labels.first_name]:'First Name',
  [tokens.labels.second_name]:'Second Name',
  [tokens.labels.third_name]:'Third Name',
  [tokens.labels.last_name]:'Last Name',
  [tokens.labels.phone_number]:'Phone Number',
  [tokens.labels.secondary_phone_number]:'Second Phone Number',
  [tokens.labels.back]:'Back',
  [tokens.labels.continue]:'Continue',
  [tokens.labels.pob]:'Place of Birth',
  [tokens.labels.dob]:'Date of Birth',
  [tokens.labels.email]:'Email Address',
  [tokens.labels.english]:'English',
  [tokens.labels.arabic]:'Arabic',
  [tokens.labels.view]:'View',

  [tokens.labels.monthly_income]:'Monthly Income',
  [tokens.labels.personal_saving]:'Personal Saving',
  [tokens.labels.bussiness_incode]:'business Income',
  [tokens.labels.other_income]:'Other Income',
  [tokens.labels.other_income_source]:'Other Income Source',


  [tokens.labels.country]:'Country',
  [tokens.labels.city]:'City',
  [tokens.labels.second_address]:'Second Address',
  [tokens.labels.type_of_residence]:'Type Of Residence',
  [tokens.labels.sales_volume]:'Sales Volume',

  [tokens.labels.max_monthly_volume]:'Max Monthly Volume',
  [tokens.labels.doe]:'Date of Establishment',
  [tokens.labels.goods_catogaries]:'Good Categories',
  [tokens.labels.supported_docs_types]:'Supported Document Types',
  [tokens.labels.add_staff_user]:'Add Staff User',
  [tokens.labels.add_other_user]:'Add Other User',
  [tokens.labels.nationality]:'Nationality',
  [tokens.labels.national_id]:'National ID',
  [tokens.labels.passport_id]:'Passport ID',
  [tokens.labels.other_nationality]:'Other Nationality',
  [tokens.labels.supported_docs_types]:'Supported Document Types',


  [tokens.labels.entity_name]:'Entity Name',
  [tokens.labels.paid_in_capital]:'Paid in capital',
  [tokens.labels.currency]:'Currency',
  [tokens.labels.shared_holders]:'Shared Holders',
  [tokens.labels.sales_volume]:'Sales volume',
  [tokens.labels.monthly_sales_volume]:'Monthly Sales Volume',
  [tokens.labels.annually_sales_volume]:'Annually Sales Volume',
  [tokens.labels.max_sales_volume]:'Max Sales Volume',
  [tokens.labels.submit]:'Submit',
  [tokens.labels.my_posts]:'My Posts',
  [tokens.labels.accountDetails]:'Account Details',
  [tokens.labels.account_verification]:'Account Verification',
  [tokens.labels.account_completion]:'Finishing',

  [tokens.labels.fill_your_name]:'Fill Names',
  [tokens.labels.university]:'University',
  [tokens.labels.college]:'College',
  [tokens.labels.academic_title]:'Academic Title',
  [tokens.labels.male]:'Male',
  [tokens.labels.female]:'Female',
  [tokens.labels.verify]:'Verify',
  [tokens.labels.verification_code]:'Verification Code',
  [tokens.labels.specific_specialization]:'Specific Specialization',
  [tokens.labels.general_specialization]:'General Specialization',
  [tokens.labels.filter]:'Filter',
  [tokens.labels.profile]:'Profile',
  [tokens.labels.settings]:'Settings',
  [tokens.labels.save]:'Save',


  [tokens.labels.search]:'Search',
  [tokens.labels.apply_filter]:'Apply Filter',
  [tokens.labels.open]:'Opened',
  [tokens.labels.close]:'Closed',
  [tokens.labels.fields]:'Fields',
  [tokens.labels.vis_type]:'Visibility',
  [tokens.labels.public]:'Public',
  [tokens.labels.private]:'Private',
  [tokens.labels.status]:'Status',
  [tokens.labels.account]:'Account',
  [tokens.labels.change_cover]:'Change Cover',
  [tokens.labels.edit]: 'Edit',
  [tokens.labels.logout]: 'Logout',
  [tokens.labels.change_password]: 'Change Password',
  [tokens.labels.old_password]: 'Old Password',
  [tokens.labels.new_password]: 'New Password',
  [tokens.labels.loading]: 'Loading',
  [tokens.labels.ago]: 'Ago',
  [tokens.labels.see_less]: 'See Less',
  [tokens.labels.see_more]: 'See More',
  [tokens.labels.private]: 'PRIVATE',
  [tokens.labels.public]: 'PUBLIC',
  [tokens.labels.all]: 'All',
  [tokens.labels.profile_updated]: 'Profile Updated',
  [tokens.labels.search_for_user]: 'Use @ to search for user',
  [tokens.labels.status]: 'Status',
  [tokens.labels.current_invitations]: 'Current Users',
  [tokens.labels.removed_users]: 'Removed',
  [tokens.labels.appended_users]: 'Newly appended',
  [tokens.labels.or_open]: 'Or Open',
  [tokens.labels.linkedin_url]: 'Linkedin URL',
  [tokens.labels.google_scholar_url]: 'Google Scholar URL',
  [tokens.labels.researchgate_url]: 'Researchgate URL',
  [tokens.labels.scopus_url]: 'Scopus URL',
  [tokens.labels.certificates_list]: 'Certificates List',
  [tokens.labels.add]: 'Add',
  [tokens.labels.fill_required_information]: 'Fill Required Information',
  [tokens.labels.title]: 'Title',

  // actions
  [tokens.actions.not_joined]: 'Join',
  [tokens.actions.joined_waiting_owner]: 'Request Sent',
  [tokens.actions.joined_accepted]: 'Accepted',
  [tokens.actions.joined_rejected]: 'Rejected',
  [tokens.actions.joined_waiting_me]: 'Rejected',
  [tokens.actions.accept]: 'Accept',
  [tokens.actions.reject]: 'Reject',


  // msgs
  [tokens.msg.here_you_can_manage_your_recent_posts]: 'Here you can manage your posts',
  [tokens.msg.hello]: 'Hello',
  [tokens.msg.do_not_have_account]: 'Don\'t have an account?',
  // Messages Section
  [tokens.msg.changing]:'changing Language...',  
  [tokens.msg.i_have_read]:'I have read and accept',  
  [tokens.msg.terms_and_conditions]:'the terms and conditions',  
  [tokens.msg.username_required]:'Username is required',  
  [tokens.msg.password_required]:'Password is required',  
  [tokens.msg.all_done]:'All Done',  
  [tokens.msg.here_is_preview_account]:'We have received your information and we will get back to you soon, Thanks a lot!.',  
  [tokens.msg.no_filter_applied]:'No Filter Applied',  
  [tokens.msg.confirmation]:'Confirmation Message',  
  [tokens.msg.change_status_confirm]:'Are you sure to change the status?',  
  [tokens.msg.please_put_your_notes]:'Please Put your notes for the action',  
  [tokens.msg.min_doc_required]:'One document at least is required',  
  [tokens.msg.please_upload_selectd_docs]:'Please uploaded all selected documents',  
  [tokens.msg.are_your_sure_to_submit]:'  Are you sure you want to submit?',  
  [tokens.msg.here_is_what_connection_posted]:'Here is the recents posts shared',  
  [tokens.msg.please_use_a_new_password]:'You can not use the same old password',  
  [tokens.msg.password_changed_successfully]:'The password has been changed successfully',  
  [tokens.msg.published_research_post]:'Published a research post',  
  [tokens.msg.search_tip_1]:'Search by entering a keyword and pressing Enter',  
  [tokens.msg.profile_updated]: 'Profile Updated',
  [tokens.msg.join_the_contributors_list]: 'Join The Contributors List',
  [tokens.msg.join_the_contributors_list_description]: 'Send a join request to the owner of the post to be able to contribute to the post',


  // validations
[tokens.val.cert_required]:'Certificate is Required',
[tokens.val.first_name_required]:'First Name is Required',
[tokens.val.second_namee_required]:'Second Name is Required',
[tokens.val.third_name_required]:'Third Name is Required',
[tokens.val.last_name_required]:'Last Name is Required',

[tokens.val.invalid_email]:'Invalid Email',
[tokens.val.dob_is_required]:'Date of Birth is Required',
[tokens.val.pob_required]:'Place of Birth is Required',
[tokens.val.choose_country]:'Choose a country',
[tokens.val.choose_city]:'Choose a city',
[tokens.val.tor_required]:'Choose Type of Residence',
[tokens.val.choose_nationality]:'Choose Nationality',
[tokens.val.nationality_required_when_iraq]:'National ID is required',
[tokens.val.passport_is_required]:'Passport Number is required',

[tokens.val.entity_name_is_required]:'Entity Name is Required',
[tokens.val.address_is_required]:'Address is Required',
[tokens.val.activity_type_is_required]:'Activity Type is Required',
[tokens.val.currency_is_required]:'Currency is Required',
[tokens.val.doe_required]:'Date of Establishment is Required',
[tokens.val.choose_provinance]:'Choose a provinance',
[tokens.val.choose_at_least_one_category]:'Choose at least one category',
[tokens.val.choose_category]:'Choose a category',
};
