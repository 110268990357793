import axios from 'axios';
import i18next from 'i18next';
import { STORAGE_KEY_USER_DATA , EXCLUDED_ENDPOINTS, BASE_URL} from '../constants';


// const HOST = process.env.REACT_APP_HOST_PRO;
// export const HOST = 'https://khasom.com';
export const HOST = process.env.REACT_APP_HOST || BASE_URL;
// console.log(`Host: ${HOST}`);
// Create a new instance of axios with defaults
const myAxios = axios.create({
  baseURL: `${HOST}/api/v1/`,
  headers: {
    // Set default headers for all requests
    'Content-Type': 'application/json',
  },
});

// Add an interceptor to exclude JWT token header for login request
myAxios.interceptors.request.use(
  (config) => {
    if (requireToken(config.url)) {
      // Add JWT token header for all requests except login
      const userData = JSON.parse(localStorage.getItem(STORAGE_KEY_USER_DATA));
      // console.log('user data: ',userData)
      if (userData.token) {
        config.headers['Authorization'] = `Bearer ${userData.token}`;
        // config.headers['jwt'] = `${token}`;

      }
    }

    // console.log(i18next.language)
    config.headers['Accept-language'] = i18next.language?(i18next.language === 'ku'?'kur':i18next.language):'ar';

    return config;
  },
  (error) => Promise.reject(error)
);

// Add a response interceptor
myAxios.interceptors.response.use(
  (response) => response,
  (error) => {
    console.error(error)
    if(!error.config.url.endsWith('/login') && error.response && error.response.status === 401)
    {
        // Redirect to login page for 403 Forbidden responses
        localStorage.removeItem(STORAGE_KEY_USER_DATA)
        window.location.href = '/auth-demo/login/modern';
    }
    // if (error.response && error.response.status === 403) {
    //   // Redirect to login page for 403 Forbidden responses
    //   localStorage.removeItem(STORAGE_KEY_USER_DATA)
    //   window.location.href = '/auth/jwt/login';
    // }
    return Promise.reject(error);
  }
);

const requireToken = (url)=>{
  const guests =  EXCLUDED_ENDPOINTS.find((e)=>url.endsWith(e))
  if(!guests || guests.length  ===0)
  {
    return true;
  }
  return false;
}
export default myAxios;
