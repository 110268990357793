import CircularProgress from '@mui/material/CircularProgress';
import {Box} from "@mui/material";
export const  MyCircularProgress = ()=><Box
sx={{
  flexGrow: 1,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}}
>
  <CircularProgress color='primary' variant="indeterminate"/>
</Box>