export const paths = {
  index: '/',
  auth: {
    
    jwt: {
      login: '/auth/jwt/login',
    },
    account: {
      register: '/auth/account/register'
    },
   
  },

  dashboard: {
    index: '/dashboard',
   
    account: '/dashboard/account',
    
   
    posts: {
      index: '/dashboard/posts/create',
      details: '/dashboard/posts/:postId',
      edit: '/dashboard/posts/:postId/edit',
      create: '/dashboard/posts/create',
      me: '/dashboard/posts/me'

    },
    
    social: {
      index: '/dashboard/social',
      profile: '/dashboard/social/:userId/profile',
      profileEdit: '/dashboard/social/profile/edit',
      feed: '/dashboard/social/feed'
    }
  },

  401: '/401',
  404: '/404',
  500: '/500'
};
